import { createStyles, makeStyles } from '@material-ui/core';

import { colorVariables } from '../../../styles/colorVariables';
import { Sizes } from '../../../styles/sizes';

export const useStyles = makeStyles((theme) =>
  createStyles({
    accountContainer: {
      paddingTop: 63,
      backgroundColor: colorVariables.white,
      [theme.breakpoints.down(Sizes.desktop)]: {
        padding: '70px 0 0 94px',
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: '110px 0 80px 0',
      },
    },
    accountContainerCustomer: {
      [theme.breakpoints.between(Sizes.mobile, Sizes.desktop)]: {
        padding: '50px 0 0 0',
      },
    },
    accountHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      backgroundColor: colorVariables.white,
      height: 127,
      borderBottom: `1px solid ${colorVariables.steelGrey}`,
      paddingBottom: 30,
      paddingLeft: 55,
      paddingRight: 55,
      [theme.breakpoints.down(Sizes.desktop)]: {
        display: 'none',
      },
    },
    accountTitle: {
      fontSize: 32,
      lineHeight: '50px',
      fontWeight: 700,
    },
    accountBody: {
      display: 'flex',
    },
    accountContent: {
      width: '80%',
      padding: '25px 200px 50px 50px',
      [theme.breakpoints.down(Sizes.desktop)]: {
        width: '100%',
        padding: '40px 50px',
      },
      [theme.breakpoints.down(Sizes.tablet)]: {
        padding: 40,
      },
      [theme.breakpoints.down(Sizes.mobile)]: {
        padding: 24,
        paddingTop: 0,
      },
    },
    title: {
      fontSize: 24,
      lineHeight: '36px',
      fontWeight: 700,
      marginBottom: 30,
      [theme.breakpoints.down(Sizes.desktop)]: {
        display: 'none',
      },
    },
    formBox: {
      width: '100%',
    },
    tabletTitleCustomer: {
      paddingBottom: 20,
      marginBottom: 30,
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        width: '100vw',
        height: 1,
        right: 0,
        left: 0,
        transform: 'translateY(20px)',
        backgroundColor: colorVariables.steelGrey,
      },
    },
    fieldWrap: {
      width: '48%',
      marginBottom: 15,
      [theme.breakpoints.down(Sizes.mobile)]: {
        width: '100%',
        '&:first-child': {
          marginRight: 0,
        },
      },
    },
    btnBox: {
      marginTop: 25,
      display: 'flex',
      justifyContent: 'left',
      paddingBottom: 30,
    },
    userId: {
      fontSize: 16,
      color: colorVariables.navy,
    }
  }),
);

import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Close, PriorityHigh } from '@material-ui/icons';
import clsx from 'clsx';

import { ToastContentProps } from 'react-toastify/dist/types';
import { ThemedButton } from '../../../../../../shared/components/themed-button';

import { useStyles } from './style';
import { ToastService } from '../../../../../../shared/services/toastService';
import { useUpdateNotificationSettingMutation } from '../../../../../../api/user';


interface Props extends ToastContentProps {
  title?: string;
  supplierId: number;
}

export const CutOffToast: React.FC<Props> = React.memo(({ title, closeToast = () => {}, supplierId }) => {
  const classes = useStyles();
  const { push } = useHistory();
  const [updateSetting] = useUpdateNotificationSettingMutation();

  const openSupplierDetails = () => {
    push(`/browse_by_supplier/${supplierId}/products`);
    closeToast();
  };

  const turnOffNotification = useCallback(() => {
    closeToast();
    ToastService.success('You can turn this back on in the notifications settings', undefined, 'Notification turned off');
    updateSetting({ notification_setting: {cut_off_time_notifications: false} });
  }, []);

  return (
    <div className={classes.root}>
      <div
        className={clsx({
          [classes.border]: true,
          [classes.borderInfo]: true,
        })}
      />
      <div
        className={clsx({
          [classes.iconWrap]: true,
          [classes.iconWrapInfo]: true,
        })}
      >
        <PriorityHigh className={classes.icon} />
      </div>
      <div className={classes.toastContent}>
        <Close onClick={closeToast} className={classes.closeBtn} />
        <div className={classes.toastTitle}>{`${title} has only 1 hour
before cut-off time. `}</div>
        <div className={classes.toastText}>Would you like to place an order?</div>
        <ThemedButton customClass={classes.supplierBtn} buttonStyle='blueButton' onClick={openSupplierDetails} title='View Supplier' />
        <span className={classes.turnOffBtn} onClick={turnOffNotification}>Turn off</span>
      </div>
    </div>
  );
});

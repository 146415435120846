import { HTTP, rootApi } from '../index';
import {
  IntegrationReqParams,
  IntegrationRes,
} from './types';

export const integrationsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getSettings: builder.query<IntegrationRes, void>({
      query: (params) => {
        return {
          url: `/integrations/settings`,
          method: HTTP.GET,
          params,
        };
      },
      keepUnusedDataFor: 0,
    }),

    updateSettings: builder.mutation<IntegrationRes, IntegrationReqParams>({
      query: (body) => {
        return {
          url: `/integrations/update_settings`,
          method: HTTP.PATCH,
          body,
        };
      },
    }),
  }),

  overrideExisting: true,
});

export const {
  useGetSettingsQuery,
  useUpdateSettingsMutation,
} = integrationsApi;

import React from 'react';

import clsx from 'clsx';
import { Field, FieldProps, Form, Formik } from 'formik';

import { useGeIsTeammateViewer } from '../../../api/teammates/hooks';
import { useGetNotificationSettingQuery, useUpdateNotificationSettingMutation } from '../../../api/user';
import { NotificationSetting } from '../../../api/user/types';
import { CommonSwitch } from '../../../shared/components/common-switch';
import { NavbarTitle } from '../../../shared/components/navbar-title';
import { useScreenSize } from '../../../shared/hooks/use-screen-size';
import { useAppDispatch, useAppSelector } from '../../../store';
import { getIsRestaurant, openAccountMenu, openMainMenu } from '../../../store/user';
import { MainNavBar } from '../../AppDrawer/MainNavBar';
import { TopNavBar } from '../../AppDrawer/MainNavBar/top-nav-bar';
import { AccountMenu } from '../account-menu';

import { useStyles } from './style';

export const Notifications: React.FC = () => {
  const classes = useStyles();
  const { isMobile, isTablet } = useScreenSize();
  const dispatch = useAppDispatch();

  const isRestaurant = useAppSelector(getIsRestaurant);
  const isViewer = useGeIsTeammateViewer();
  const title = 'Notifications';

  const { settings, loading } = useGetNotificationSettingQuery(undefined, {
    selectFromResult: ({ data, isLoading }) => ({
      settings: data?.notification_setting,
      loading: isLoading,
    }),
  });
  const [updateSetting] = useUpdateNotificationSettingMutation();

  const openMenus = () => {
    dispatch(openMainMenu());
    dispatch(openAccountMenu());
  };

  const onSubmit = (values: NotificationSetting) => {
    updateSetting({ notification_setting: values });
  };

  return (
    <>
      <TopNavBar
        isAccount={true}
        leftComponent={<NavbarTitle title={title} showBackBtn={true} backHandler={openMenus} />}
        bottomComponent={isMobile ? <NavbarTitle title={title} showBackBtn={true} backHandler={openMenus} /> : undefined}
      />
      <MainNavBar isAccount={true} />
      <div className={clsx(classes.accountContainer, isRestaurant && classes.accountContainerCustomer)}>
        <div className={classes.accountHeader}>
          <div className={classes.accountTitle}>My Account</div>
        </div>
        <div className={classes.accountBody}>
          <AccountMenu showBack={!isRestaurant} />
          <div className={classes.accountContent}>
            {isRestaurant && isTablet && (
              <div className={classes.tabletTitleCustomer}>
                <NavbarTitle title={title} showBackBtn={true} backHandler={openMenus} />
              </div>
            )}
            <div className={classes.title}>{title}</div>
            {!loading && (
              <Formik
                enableReinitialize={true}
                initialValues={
                  settings || {
                    new_orders: !isRestaurant,
                    connection_requests: true,
                    transactional_emails: !isRestaurant,
                    promotional: true,
                    order_receipts_for_rs: isRestaurant,
                    transactional_emails_for_rs: false,
                    important_emails_for_rs: false,
                    cut_off_time_notifications: true,
                    inventory_trigger_notifications: true,
                  }
                }
                onSubmit={onSubmit}
              >
                <Form className={classes.formBox}>
                  {isRestaurant ? (
                    <div className={classes.switchField}>
                      <span className={classes.switchFieldText}>Order Receipts</span>
                      <Field name='order_receipts_for_rs'>
                        {(fieldProps: FieldProps) => <CommonSwitch {...fieldProps} disabled={isViewer} />}
                      </Field>
                    </div>
                  ) : (
                    <div className={classes.switchField}>
                      <span className={classes.switchFieldText}>New Orders</span>
                      <Field name='new_orders'>{(fieldProps: FieldProps) => <CommonSwitch {...fieldProps} disabled={isViewer} />}</Field>
                    </div>
                  )}
                  <div className={classes.switchField}>
                    <span className={classes.switchFieldText}>Connection requests</span>
                    <Field name='connection_requests'>
                      {(fieldProps: FieldProps) => <CommonSwitch {...fieldProps} disabled={isViewer} />}
                    </Field>
                  </div>
                  {isRestaurant ? (
                    <div className={classes.switchField}>
                      <span className={clsx(classes.switchFieldText, classes.inActive)}>Transactional Emails</span>
                      <Field name='transactional_emails_for_rs'>
                        {(fieldProps: FieldProps) => <CommonSwitch {...fieldProps} disabled={true} />}
                      </Field>
                    </div>
                  ) : (
                    <div className={classes.switchField}>
                      <span className={classes.switchFieldText}>Transactional Emails</span>
                      <Field name='transactional_emails'>
                        {(fieldProps: FieldProps) => <CommonSwitch {...fieldProps} disabled={isViewer} />}
                      </Field>
                    </div>
                  )}
                  <div className={classes.switchField}>
                    <span className={classes.switchFieldText}>Promotional Emails</span>
                    <Field name='promotional'>{(fieldProps: FieldProps) => <CommonSwitch {...fieldProps} disabled={isViewer} />}</Field>
                  </div>
                  {isRestaurant && (
                    <>
                      <div className={classes.switchField}>
                        <span className={classes.switchFieldText}>Important Emails</span>
                        <Field name='important_emails_for_rs'>
                          {(fieldProps: FieldProps) => <CommonSwitch {...fieldProps} disabled={isViewer} />}
                        </Field>
                      </div>
                      <div className={classes.switchField}>
                        <span className={classes.switchFieldText}>Cut-off time notifications</span>
                        <Field name='cut_off_time_notifications'>
                          {(fieldProps: FieldProps) => <CommonSwitch {...fieldProps} disabled={isViewer} />}
                        </Field>
                      </div>
                      <div className={classes.switchField}>
                        <span className={classes.switchFieldText}>Inventory Trigger notifications</span>
                        <Field name='inventory_trigger_notifications'>
                          {(fieldProps: FieldProps) => <CommonSwitch {...fieldProps} disabled={isViewer} />}
                        </Field>
                      </div>
                    </>
                  )}
                </Form>
              </Formik>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
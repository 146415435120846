import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { useGeHiddenPages } from '../../api/teammates/hooks';
import { useGetNotificationSettingQuery } from '../../api/user';
import { SubscriptionPlanEnum } from '../../api/user/types';
import { NotFoundPage } from '../../shared/components/not-found-page';
import { useScreenSize } from '../../shared/hooks/use-screen-size';
import { useAppSelector } from '../../store';
import { getIsRestaurant, getMySubscriptionType } from '../../store/user';
import { Billing } from './Billing';
import { CustomerProfile } from './CustomerProfile';
import EditManualSupplier from './EditManualSupplier';
import { HelpPage } from './Help';
import { Notifications } from './Notifications';
import OnBoardManualSupplier from './OnboardManualSupplier';
import { ProfilePage } from './Profile';
import { AddHolidaysPage } from './Profile/add-holidays-page';
import { AddPostCodesPage } from './Profile/add-post-codes-page';
import { ViewPostCodesPage } from './Profile/view-post-codes-page';
import { SupplierProfile } from './SupplierProfile';
import { SuppliersPage } from './SuppliersCategories';
import { ManualProductsMobile } from './SuppliersCategories/ManualProductsMobile';
import { Teammates } from './Teammates';
import { VenueInfo } from './VenueInfo';
import { AddressForm } from './VenueInfo/address-form';
import { Integrations } from './Integrations';

const AccountDrawer: React.FC = () => {
  const isRestaurant = useAppSelector(getIsRestaurant);
  const subscriptionType = useAppSelector(getMySubscriptionType);
  const { isMobile, isTabletPortrait } = useScreenSize();
  useGetNotificationSettingQuery(undefined);
  const isSuppliersHidden = !!useGeHiddenPages('supplier_and_categories');
  const isBillingHidden = !!useGeHiddenPages('billing');
  const isTeammatesHidden = !!useGeHiddenPages('teammates');

  return (
    <Switch>
      <Route path={'/account'} exact={true} component={isRestaurant ? CustomerProfile : ProfilePage} />
      <Route path={'/account/profile'} component={isRestaurant ? CustomerProfile : SupplierProfile} />
      <Route path={'/account/notifications'} component={Notifications} />
      {subscriptionType !== SubscriptionPlanEnum.FREE && !isTeammatesHidden && (
        <Route path={'/account/teammates'} component={isRestaurant ? Teammates : () => null} />
      )}
      <Route path={'/account/Integrations'} component={Integrations} />
      <Route path={'/account/help'} component={HelpPage} />
      {isRestaurant && <Route path={'/account/onboard-manual-supplier'} component={OnBoardManualSupplier} />}
      {isRestaurant && <Route path='/account/edit-manual-supplier/:id' component={EditManualSupplier} />}
      {!isRestaurant && isMobile && <Route path={'/account/view-postcodes'} component={ViewPostCodesPage} />}
      {!isRestaurant && isMobile && <Route path={'/account/add-postcodes'} component={AddPostCodesPage} />}
      {!isRestaurant && (isMobile || isTabletPortrait) && <Route path={'/account/add-holidays'} component={AddHolidaysPage} />}
      {!isBillingHidden && <Route path={'/account/billing'} component={Billing} />}
      {isRestaurant ? (
        <Switch>
          <Route path={'/account/venue'} component={VenueInfo} exact={true} />
          {isMobile && <Route path={'/account/supplier/:id/products'} component={ManualProductsMobile} />}
          {!isSuppliersHidden && <Route path={'/account/suppliers'} component={SuppliersPage} />}
          <Route path={'/account/venue/add'} component={AddressForm} />
          <Route path={'/account/venue/edit'} component={AddressForm} />
          <Route path='/account' component={NotFoundPage} />
        </Switch>
      ) : (
        <Route path='/account' component={NotFoundPage} />
      )}
    </Switch>
  );
};

export default AccountDrawer;

import { ChangeEvent, KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { differenceInMinutes, isBefore, isToday } from 'date-fns';
import { toast } from 'react-toastify';
import { useDebouncedCallback } from 'use-debounce';

import { CutOffToast } from '../../components/AppDrawer/MainNavBar/top-nav-bar/cart/cutt-off-toast';
import { getTimeFromPastDate } from '../../shared/helpers/getTimeFromPastDate';
import { parseFloatFromString } from '../../shared/helpers/parseFloatFromString';
import useInterval from '../../shared/hooks/use-interval';
import { useScreenSize } from '../../shared/hooks/use-screen-size';
import { LocalStorageService } from '../../shared/services/localStorage.service';
import { toastOptions } from '../../shared/services/toastService';
import { useAppDispatch, useAppSelector } from '../../store';
import { getMyId } from '../../store/user';
import { rootApi } from '../index';
import { useGetMySuppliers } from '../supplier/hooks';
import { useAddToCartMutation, useGetCartQuery, useUpdateQuantityInCartMutation } from './index';
import { Cart, ProductInCart } from './types';
import { getCutOffTimeNotifications } from '../../store/user/user.selectors';
import { roundUpToNearestUnit } from '../../shared/helpers/roundNumbers';

export const useGetCartProductInfo = (id: number) => {
  const { countInCart = 0 } = useGetCartQuery(undefined, {
    selectFromResult: ({ data }) => ({
      countInCart: data?.carts.reduce((acc, cartItem) => {
        const selectedProduct = cartItem.cart_products.find((product) => product.product_id === id);
        if (selectedProduct) {
          acc = selectedProduct.quantity;
        }
        return acc;
      }, 0),
    }),
  });

  return { countInCart };
};

export const useGetCart = () => {
  const { totalCount, total, itemsCount, list, cartInfo, deliveryAddressId, loading, totalToCheck, initLoading, isReplenish } =
    useGetCartQuery(undefined, {
      selectFromResult: ({ data, isFetching, isLoading }) => ({
        totalCount:
          data?.carts.reduce((acc, cartItem) => {
            cartItem.cart_products.forEach((el) => {
              acc += el.quantity;
            });
            return acc;
          }, 0) || 0,
        itemsCount:
          data?.carts.reduce((acc, cartItem) => {
            acc += cartItem.cart_products.length;
            return acc;
          }, 0) || 0,
        total:
          data?.carts.reduce((acc, cartItem) => {
            acc += cartItem.supplier.show_prices ? cartItem.total_price : 0;
            return acc;
          }, 0) || 0,
        list: (data?.carts
          ? data.carts
              .map((cart) => ({
                ...cart,
                products: [...cart.cart_products]
                  .sort((a, b) => (a.id > b.id ? 1 : -1))
                  .map((el) => cart.products.find((product) => product.id === el.product_id) as ProductInCart),
              }))
              .sort((a, b) => (a.id > b.id ? 1 : -1))
          : []) as Cart[],
        isReplenish: data?.carts?.some((el) => el.replenish),
        totalToCheck:
          data?.carts.reduce((acc, cartItem) => {
            acc += cartItem.total_price;
            return acc;
          }, 0) || 0,
        cartInfo: {
          finalTotal: data?.final_total || 0,
          tax: data?.total_tax || 0,
          delivery: data?.total_shipping || 0,
          ordersToday: data?.orders_today,
        },
        loading: isFetching,
        initLoading: isLoading,
        deliveryAddressId:
          data?.delivery_addresses.find((address) => address.default_address)?.id ||
          (data?.delivery_addresses || []).reduce((acc: null | number, address, i, arr) => {
            if (
              arr
                ?.filter((addItem) => addItem.id === acc)
                .every((add) => isBefore(new Date(add.created_at as string), new Date(address.created_at as string)))
            ) {
              acc = address.id;
            }
            return acc;
          }, null) ||
          null,
      }),
    });

  return { totalCount, total, itemsCount, list, cartInfo, deliveryAddressId, loading, totalToCheck, initLoading, isReplenish };
};

export const useRefetchCart = () => {
  const { list } = useGetCart();
  const dispatch = useAppDispatch();
  const shouldRefetch = useMemo(() => {
    return list.some((el) =>
      el.cart_products.some((pr) => {
        try {
          return isToday(new Date(pr.id));
        } catch {
          return false;
        }
      }),
    );
  }, [list]);
  useInterval(
    () => {
      dispatch(rootApi.util.invalidateTags(['Cart']));
    },
    shouldRefetch ? 60000 : null,
  );
};

export const useCutOffToast = () => {
  const cutOffTimeNotifications = useAppSelector(getCutOffTimeNotifications);
  const myId = useAppSelector(getMyId);
  const { suppliers } = useGetMySuppliers(true, true);

  const checkSupplierCutOff = () => {
    if(cutOffTimeNotifications === false) {
      return;
    }
    const pushList = suppliers.filter((el) => {
      return (
        el.delivery_detail.cutoff &&
        differenceInMinutes(getTimeFromPastDate(new Date(el.delivery_detail.cutoff)), new Date()) < 60 &&
        differenceInMinutes(getTimeFromPastDate(new Date(el.delivery_detail.cutoff)), new Date()) > 0
      );
    });

    for (const supplier of pushList) {
      const storedNotificationIds = LocalStorageService.getItem(`cutoff_${myId}`);
      const todayNotificationsIds = Array.isArray(storedNotificationIds)
        ? storedNotificationIds.filter((notif) => isToday(new Date(notif.shown)))
        : [];
      if (
        !todayNotificationsIds.some(
          (el: { id: number; shown: string; type: 'toast' | 'push' }) => el.id === supplier.id && el.type === 'toast',
        )
      ) {
        toast((props) => <CutOffToast {...props} supplierId={supplier.id} title={supplier.company?.name} />, {
          ...toastOptions,
          autoClose: false,
          toastId: supplier.id,
          closeButton: () => <div />,
          closeOnClick: false,
        });
        LocalStorageService.setItem(`cutoff_${myId}`, [
          ...todayNotificationsIds.filter((nEl) => !(nEl.id === supplier.id && nEl.type === 'toast')),
          { id: supplier.id, shown: new Date(), type: 'toast' },
        ]);
        return;
      }
    }

    for (const supplier of pushList) {
      const storedNotificationIds = LocalStorageService.getItem(`cutoff_${myId}`);
      const todayNotificationsIds = Array.isArray(storedNotificationIds)
        ? storedNotificationIds.filter((notif) => isToday(new Date(notif.shown)))
        : [];
      if (
        !todayNotificationsIds.some(
          (el: { id: number; shown: string; type: 'toast' | 'push' }) => el.id === supplier.id && el.type === 'push',
        )
      ) {
        if ('serviceWorker' in navigator && 'PushManager' in window) {
          const title = 'Open Pantry Notification';
          const options = {
            body: `${supplier.company?.name} has only 1 hour before cut-off time. `,
            icon: '/logo144.png',
          };
          navigator.serviceWorker.ready.then((registration) => {
            registration.showNotification(title, options);
          });
          LocalStorageService.setItem(`cutoff_${myId}`, [
            ...todayNotificationsIds.filter((nEl) => !(nEl.id === supplier.id && nEl.type === 'push')),
            { id: supplier.id, shown: new Date(), type: 'push' },
          ]);
          return;
        }
      }
    }
  };

  useInterval(checkSupplierCutOff, 30000);
};

export const useCartControls = (productId: number, chargeUnit?: string | null) => {
  const { isMobile } = useScreenSize();

  const inputRef = useRef<HTMLInputElement | null>(null);
  const [localCartCount, setLocalCartCount] = useState('0');
  const { countInCart } = useGetCartProductInfo(productId);
  const [updateCartQuantity] = useUpdateQuantityInCartMutation();
  const [addToCart] = useAddToCartMutation();

  const debouncedFocus = useDebouncedCallback(() => {
    inputRef?.current?.focus();
  }, 750);

  const debouncedUpdate = useDebouncedCallback(() => {
    const chargeQty = parseFloatFromString(chargeUnit);
    updateCartQuantity({
      product_id: productId,
      quantity: Math.round((countInCart + (chargeQty || 1)) * 100) / 100,
    });
  }, 150);

  const onAddToCart = useCallback(
    (invalidate: boolean) => {
      if (+localCartCount === 0) {
        addToCart({ product_id: productId, invalidate });
      } else {
        debouncedUpdate();
      }
      !isMobile && debouncedFocus();
    },
    [productId, countInCart, localCartCount, setLocalCartCount, chargeUnit],
  );

  const handleCountChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    e.persist();
    const chargeQty = parseFloatFromString(chargeUnit);
    const count = e.target.value.replace(',', '.');
    const decimals = count.split('.')[1]?.slice(0, 2);
    const wholeNumber = count.split('.')[0];
    const countToSet = decimals || count.includes('.') ? `${wholeNumber || '0'}.${decimals || ''}` : wholeNumber;
    if (isNaN(+countToSet) || +countToSet < 0 || +countToSet > 9999) {
      return;
    }
    let roundUp = +countToSet;
    if(chargeQty > 0) {
      roundUp = roundUpToNearestUnit(roundUp, chargeQty);
      setLocalCartCount(roundUp.toString());
    } else {
      setLocalCartCount(countToSet.toString());
    }

    !!roundUp &&
      updateCartQuantity({
        product_id: productId,
        quantity: roundUp,
      });
  }, [chargeUnit]);

  const decrementCart = useCallback(() => {
    if (+localCartCount === 0 || countInCart === 0) {
      return;
    }
    const chargeQty = parseFloatFromString(chargeUnit);
    if (chargeQty && countInCart >= chargeQty) {
      updateCartQuantity({
        product_id: productId,
        quantity: Math.round((countInCart - chargeQty) * 100) / 100,
      });
    } else {
      updateCartQuantity({
        product_id: productId,
        quantity: +countInCart.toFixed(0) === countInCart ? countInCart - 1 : Math.floor(countInCart),
      });
    }
  }, [localCartCount, countInCart, productId, chargeUnit]);

  const onEnterPress = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.code === 'Enter') {
        onBlur();
      }
    },
    [localCartCount, countInCart],
  );

  const onBlur = useCallback(() => {
    if (+localCartCount === countInCart) {
      return;
    }
    updateCartQuantity({
      product_id: productId,
      quantity: +localCartCount,
    });
  }, [productId, localCartCount, countInCart]);

  useEffect(() => {
    countInCart !== +localCartCount && setLocalCartCount(countInCart.toString());
  }, [countInCart]);

  return { onAddToCart, decrementCart, onEnterPress, onBlur, handleCountChange, inputRef, countInCart, localCartCount };
};

import React, { useEffect, useState } from 'react';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import clsx from 'clsx';

import { useUpdateTeammateMutation } from '../../../../api/teammates';
import { Teammate, TeammatePermission } from '../../../../api/teammates/types';
import { DropdownMenu } from '../../../../shared/components/dropdown-menu';
import { useAppDispatch } from '../../../../store';
import { setCustomTeammatePermissionModal } from '../../../../store/teammates/teammates.actions';

import { useStyles } from './style';

interface Props {
  item: Teammate;
  setPermission?: (option: TeammatePermission) => void;
}

export const SubmitPermissionDropdown: React.FC<Props> = ({ item, setPermission }) => {
  const classes = useStyles();
  const [currentPermission, setCurrentPermission] = useState<TeammatePermission>(item.permission);

  const [updateTeammate] = useUpdateTeammateMutation();
  const dispatch = useAppDispatch();

  const onSubmitPermission = (permission: TeammatePermission) => {
    if (permission === TeammatePermission.CUSTOM) {
      dispatch(setCustomTeammatePermissionModal(item.id));
      return;
    }
    setCurrentPermission(permission);
    if (setPermission) {
      setPermission(permission);
    } else {
      updateTeammate({
        id: item.id,
        permission,
        teammate: {
          first_name: item.first_name,
          email: item.email,
        },
      });
    }
  };

  useEffect(() => {
    setCurrentPermission(item.permission);
  }, [item.permission]);

  return (
    <DropdownMenu
      items={[
        <div key='View' onClick={onSubmitPermission.bind(null, TeammatePermission.VIEWER)}>
          Viewer
        </div>,
        <div key='Editor' onClick={onSubmitPermission.bind(null, TeammatePermission.EDITOR)}>
          Editor
        </div>,
        <div key='Admin' onClick={onSubmitPermission.bind(null, TeammatePermission.ADMIN)}>
          Admin
        </div>,
        <div key='Custom Mode' onClick={onSubmitPermission.bind(null, TeammatePermission.CUSTOM)}>
          Custom Mode
        </div>,
      ]}
      triggerBtn={
        <div className={clsx(classes.optionTrigger, currentPermission === TeammatePermission.CUSTOM && classes.optionTriggerCustom)}>
          <span>{currentPermission}</span>
          <KeyboardArrowDownIcon />
        </div>
      }
    />
  );
};

import React from 'react';

import clsx from 'clsx';
import { Field, FieldProps, Form, Formik } from 'formik';

import { NavbarTitle } from '../../../shared/components/navbar-title';
import { useScreenSize } from '../../../shared/hooks/use-screen-size';
import { useAppDispatch, useAppSelector } from '../../../store';
import { getIsRestaurant, openAccountMenu, openMainMenu } from '../../../store/user';
import { MainNavBar } from '../../AppDrawer/MainNavBar';
import { TopNavBar } from '../../AppDrawer/MainNavBar/top-nav-bar';
import { AccountMenu } from '../account-menu';

import { useStyles } from './style';
import { FormikInput } from '../../../shared/components/formik-input';
import { useGetSettingsQuery, useUpdateSettingsMutation } from '../../../api/integrations';
import { ThemedButton } from '../../../shared/components/themed-button';
import { ToastService } from '../../../shared/services/toastService';
import { useMeQuery } from '../../../api/user';
import { FormLabel } from '@material-ui/core';

export const Integrations: React.FC = () => {
  const classes = useStyles();
  const { isMobile } = useScreenSize();
  const dispatch = useAppDispatch();

  const isRestaurant = useAppSelector(getIsRestaurant);
  const { data: user } = useMeQuery();
  const title = 'Integrations';

  const { data } = useGetSettingsQuery();
  const [updateSettings, { isLoading: loading }] = useUpdateSettingsMutation();

  const openMenus = () => {
    dispatch(openMainMenu());
    dispatch(openAccountMenu());
  };

  const onSubmit = ({order_webhook}:{order_webhook: string}) => {
    updateSettings({ webhook: { url: order_webhook } })
      .unwrap()
      .then(() => {
        ToastService.success('The settings have been updated successfully');
      })
      .catch((error) => {
        console.error('Update failed:', error);
      });
  };

  return (
    <>
      <TopNavBar
        isAccount={true}
        leftComponent={<NavbarTitle title={title} showBackBtn={true} backHandler={openMenus} />}
        bottomComponent={isMobile ? <NavbarTitle title={title} showBackBtn={true} backHandler={openMenus} /> : undefined}
      />
      <MainNavBar isAccount={true} />
      <div className={clsx(classes.accountContainer, isRestaurant && classes.accountContainerCustomer)}>
        <div className={classes.accountHeader}>
          <div className={classes.accountTitle}>My Account</div>
        </div>
        <div className={classes.accountBody}>
          <AccountMenu showBack={!isRestaurant} />
          <div className={classes.accountContent}>
            <div className={classes.title}>{title}</div>
            <Formik
              initialValues={{
                api_key: data?.api_key.key || '',
                order_webhook: data?.webhook.url || '',
                user_id: user?.id || '',
              }}
              onSubmit={onSubmit}
              enableReinitialize={true}
            >
              {({ submitForm }) => (
              <Form className={classes.formBox}>
                <div className={classes.fieldWrap}>
                  <Field name='user_id'>
                    {(fieldProps: FieldProps) => (
                      <FormLabel className={classes.userId}>User ID: {fieldProps.field.value}</FormLabel>
                    )}
                  </Field>
                </div>
                <div className={classes.fieldWrap}>
                  <Field name='api_key'>
                    {(fieldProps: FieldProps) => (
                      <FormikInput {...fieldProps} label='API Key' placeholder='API Key' disabled={true} />
                    )}
                  </Field>
                </div>
                <div className={classes.fieldWrap}>
                  <Field name='order_webhook'>
                    {(fieldProps: FieldProps) => (
                      <FormikInput {...fieldProps} label='Order Webhook' placeholder='ex: https://localhost/order' disabled={false} />
                    )}
                  </Field>
                </div>
                <div className={classes.btnBox}>
                  <ThemedButton disabled={loading} onClick={submitForm} title='Update' width={200} />
                </div>
              </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};
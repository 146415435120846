import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Collapse, Switch } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import { format } from 'date-fns';
import LinesEllipsis from 'react-lines-ellipsis';

import {
  useGetFreeDeliveryAtQuery,
  useGetMyCompanyQuery,
  useUpdateAbnMutation,
  useUpdateFreeDeliveryAtMutation,
  useUpdateSupplierProfileMutation,
} from '../../../api/company';
import { ContactType, DeliveryDay, SupplierProfile } from '../../../api/company/types';
import { useMeQuery } from '../../../api/user';
import { SubscriptionPlanEnum } from '../../../api/user/types';
import SvgLockIcon from '../../../assets/icons/LockIcon';
import SvgUploadPhoto from '../../../assets/icons/UploadPhoto';
import { AddButton } from '../../../shared/components/add-button';
import { BlurredImage } from '../../../shared/components/blurred-image';
import { ThemedButton } from '../../../shared/components/themed-button';
import { CommonCheckbox } from '../../../shared/components/common-checkbox';
import { EditIcon } from '../../../shared/components/edit-button';
import { NavbarTitle } from '../../../shared/components/navbar-title';
import { productsCategoriesList } from '../../../shared/constants/products';
import { week } from '../../../shared/constants/week';
import { calculateProfileProgress } from '../../../shared/helpers/calculate-profile-progress';
import { formatCurrency } from '../../../shared/helpers/format-currency';
import { getCategoriesFromIds, getCategoryId } from '../../../shared/helpers/getCategoryId';
import { setGtmDatalayer } from '../../../shared/helpers/setGtmDatalayer';
import { useScreenSize } from '../../../shared/hooks/use-screen-size';
import { LocalStorageService } from '../../../shared/services/localStorage.service';
import { useAppDispatch, useAppSelector } from '../../../store';
import { getMySubscriptionType, openAccountMenu, openMainMenu } from '../../../store/user';
import { colorVariables } from '../../../styles/colorVariables';
import { MainNavBar } from '../../AppDrawer/MainNavBar';
import { TopNavBar } from '../../AppDrawer/MainNavBar/top-nav-bar';
import { AccountMenu } from '../account-menu';
import { AddressForm } from './address-form';
import { AdvancedDeliveryContent } from './advanced-delivery-content';
import { BusinessDetailsForm } from './business-details-form';
import { CompanyDetailsForm } from './company-details-form';
import { DeliveryDaysBlock } from './delivery-days';
import { DeliveryDetailsForm } from './delivery-details-form';
import { InitialSetUp } from './initial-set-up';
import { OrderDetails } from './order-details';
import { ProductCategories } from './product-categories';
import { ProfileProgressBar } from './profile-progress-bar';
import { SetUpCompleted } from './set-up-completed';
import { UploadLogo } from './upload-logo';

import { useRippleStyles } from '../../../styles/customRipple';
import { useStyles } from './style';

export enum ProfileModals {
  BUSINESS_DETAILS = 'BUSINESS_DETAILS',
  COMPANY_DETAILS = 'COMPANY_DETAILS',
  ADDRESS = 'ADDRESS',
  DELIVERY_DETAILS = 'DELIVERY_DETAILS',
  DELIVERY_DAYS = 'DELIVERY_DAYS',
  PRODUCT_TYPES = 'PRODUCT_TYPES',
  ORDER_DETAILS = 'ORDER_DETAILS',
  UPLOAD_LOGO = 'UPLOAD_LOGO',
}

enum ExpandedSection {
  contact,
  personal,
  delivery,
  advanced,
  products,
}

export const ProfilePage: React.FC = () => {
  const classes = useStyles();
  const rippleClass = useRippleStyles();
  const { push } = useHistory();

  const { isTablet, isMobile } = useScreenSize();
  const dispatch = useAppDispatch();

  const subscriptionType = useAppSelector(getMySubscriptionType);
  const abnText = LocalStorageService.getItem('country') === 'AU' ? 'Business ID/Number' : 'Company registration number (Optional)';
  const isWhatsAppOrdering = LocalStorageService.getItem('country') === 'ID';

  const { data: user } = useMeQuery();
  const { data: company } = useGetMyCompanyQuery(user?.company?.id, {
    skip: !user?.company.id,
  });
  const [updateProfile, { isSuccess, isLoading }] = useUpdateSupplierProfileMutation();
  const { data: freeDeliveryAt } = useGetFreeDeliveryAtQuery();
  const [updateFreeDeliveryAt] = useUpdateFreeDeliveryAtMutation();
  const [updateAbn] = useUpdateAbnMutation();
  const [topUpOrders, setTopUpOrders] = useState(company?.delivery_details[0].top_up_orders);
  const [profileProgress, setProfileProgress] = useState(0);
  const [freeDeliveryAtValue, setFreeDeliveryAtValue] = useState(0);
  const [abnNumberValue, setAbnNumberValue] = useState('');
  const [profile, setProfile] = useState<Partial<SupplierProfile>>({});
  const [isOnBoarding, setIsOnBoarding] = useState(false);
  const [isOnBoardingModalOpen, setIsOnBoardingModalOpen] = useState(false);
  const [isCompletedModalOpen, setIsCompletedModalOpen] = useState(false);
  const [openedModal, setOpenedModal] = useState<ProfileModals | null>(null);
  const [expandedSections, setExpandedSections] = useState<ExpandedSection[]>([]);

  const toggleExpandedSection = (section: ExpandedSection) => {
    setExpandedSections((prev) => (prev.includes(section) ? prev.filter((el) => el !== section) : [...prev, section]));
  };

  const closeModal = () => {
    if (isOnBoarding) {
      setIsOnBoarding(false);
    }
    setOpenedModal(null);
  };

  const updateProfileProgress = (progress: number) => {
    setProfileProgress(progress);
  };

  const onSubmitForm = (data: Partial<SupplierProfile>) => {
    setProfile((prev) => ({ ...prev, ...data }));
  };

  const closeOnBoardingModal = () => {
    setIsOnBoardingModalOpen(false);
  };

  const onUpgrade = () => {
    push('/account/billing/subscriptions');
  };

  const onSetOnBoarding = () => {
    setOpenedModal(ProfileModals.BUSINESS_DETAILS);
    setIsOnBoarding(true);
  };

  const setNextStep = (step: ProfileModals | null) => {
    setOpenedModal(step);
  };

  const closeSetUpCompleted = () => {
    setIsCompletedModalOpen(false);
  };

  const openMenus = () => {
    dispatch(openMainMenu());
    dispatch(openAccountMenu());
  };

  const setAbnValue = (abn: string) => {
    setAbnNumberValue(abn);
  };

  const onSaveProfile = () => {
    if (!company?.id) {
      return;
    }
    const formData = new FormData();
    formData.append('company[name]', profile.name || company.name);
    profile.bio && formData.append('company[bio]', profile.bio);
    profile.website && formData.append('company[website]', profile.website);
    profile.image && formData.append('company[picture]', profile.image);

    profile.contacts?.forEach(({ contact_type, email, phone_number, id }, idx) => {
      contact_type && formData.append(`company[contacts_attributes][${idx}][contact_type]`, contact_type);
      phone_number && formData.append(`company[contacts_attributes][${idx}][phone_number]`, phone_number);
      email && formData.append(`company[contacts_attributes][${idx}][email]`, email);
      id && formData.append('company[contacts_attributes][0][id]', id.toString());
    });
    formData.append(
      'company[delivery_details_attributes][0][minimum_order_value_cents]',
      ((profile.min_order_value || 0) * 100).toString(),
    );
    profile?.delivery_detail_id && formData.append('company[delivery_details_attributes][0][id]', profile.delivery_detail_id.toString());
    user?.id && formData.append('company[delivery_details_attributes][0][user_id]', user.id.toString());
    profile.cutoff && formData.append('company[delivery_details_attributes][0][cutoff]', profile.cutoff);
    profile.delivery_days &&
      week.forEach((day) => {
        formData.append(
          `company[delivery_details_attributes][0][${day.key}]`,
          profile.delivery_days?.includes(day.key).toString() || 'false',
        );
      });
    profile.delivery_fee &&
      formData.append('company[delivery_details_attributes][0][delivery_fee_cents]', ((profile.delivery_fee || 0) * 100).toString());
    user?.id && formData.append('company[suppliers_attributes][0][id]', user.id.toString());
    profile.delivery_radius_id &&
      formData.append('company[suppliers_attributes][0][delivery_radiuses_attributes][0][id]', profile.delivery_radius_id.toString());
    formData.append(
      'company[suppliers_attributes][0][delivery_radiuses_attributes][0][delivery_radius]',
      (profile.delivery_radius || 0).toString(),
    );
    const categoriesToAdd =
      profile.categories?.filter((category) => !company.categories.some((cc) => cc.id === getCategoryId(category))) || [];
    const categoriesToRemove =
      company.categories.filter((cc) => !profile.categories?.some((category) => cc.id === getCategoryId(category))) || [];
    categoriesToAdd.forEach((category, idx) => {
      formData.append(`company[company_categories_attributes][${idx}][category_id]`, getCategoryId(category).toString());
    });
    categoriesToRemove.forEach((category, idx) => {
      const idToRemove = company?.company_categories?.find((cat) => cat.category_id === category.id)?.id;
      if (!idToRemove) {
        return;
      }
      formData.append(`company[company_categories_attributes][${idx + categoriesToAdd.length}][id]`, idToRemove.toString());
      formData.append(`company[company_categories_attributes][${idx + categoriesToAdd.length}][_destroy]`, 'true');
    });
    updateProfile({
      formData,
      id: company?.id,
    });
  };

  const onUploadLogoClick = () => {
    setNextStep(ProfileModals.UPLOAD_LOGO);
  };

  const onUpdateDescriptionClick = () => {
    setNextStep(ProfileModals.BUSINESS_DETAILS);
  };

  const onUpdateDeliveryRadiusClick = () => {
    setNextStep(ProfileModals.DELIVERY_DETAILS);
  };

  const onUpdateDeliveryFeeClick = () => {
    setNextStep(ProfileModals.DELIVERY_DETAILS);
  };

  const onUpdateProductTypeClick = () => {
    setNextStep(ProfileModals.PRODUCT_TYPES);
  };

  const onUpdateCompanyClick = () => {
    setNextStep(ProfileModals.COMPANY_DETAILS);
    setGtmDatalayer({
      event: 'profile',
      eventCategory: 'company_profile_update',
    });
  };

  const onUpdateMinimumOrderClick = () => {
    setNextStep(ProfileModals.ORDER_DETAILS);
  };

  const toggleEmailAllOrders = () => {
    if (isLoading) {
      return;
    }
    const formData = new FormData();
    profile.contacts?.forEach(({ contact_type, email, id, is_order_email }) => {
      if (contact_type === ContactType.ORDER && email && id) {
        formData.append('company[contacts_attributes][0][id]', id.toString());
        formData.append(`company[contacts_attributes][0][is_order_email]`, (!is_order_email).toString());
      }
    });
    company &&
      updateProfile({
        formData,
        id: company?.id,
      });
  };

  const setFreeDelivery = (value: number) => {
    setFreeDeliveryAtValue(value);
  };

  useEffect(() => {
    if (user && company) {
      const deliveryRadiusData = 'delivery_radiuses' in user ? user?.delivery_radiuses.find((el) => el.supplier_id === user.id) : undefined;
      const deliveryDetail = company.delivery_details[0];
      const profileData: Partial<SupplierProfile> = {
        name: company.name,
        bio: company.bio || '',
        website: company.website || '',
        picture_url: company.picture_url || '',
        min_order_value: (deliveryDetail?.minimum_order_value_cents || 0) / 100,
        cutoff: deliveryDetail?.cutoff,
        delivery_detail_id: deliveryDetail?.id,
        delivery_days: deliveryDetail
          ? Object.values(DeliveryDay).filter((day) => deliveryDetail[day])
          : Object.values(DeliveryDay).filter((el) => el !== DeliveryDay.SUNDAY),
        categories: getCategoriesFromIds(company.categories),
        delivery_radius: deliveryRadiusData?.delivery_radius,
        delivery_radius_id: deliveryRadiusData?.id,
        delivery_fee: (deliveryDetail?.delivery_fee?.cents || 0) / 100,
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        contacts: company.contacts || [],
        office_email: company.contacts?.find((el) => el.contact_type === ContactType.OFFICE)?.email || '',
        office_phone: company.contacts?.find((el) => el.contact_type === ContactType.OFFICE)?.phone_number || '',
        address: company.addresses[0],
      };
      const progress = calculateProfileProgress(profileData);
      updateProfileProgress(progress);
      if (progress <= 60 && !LocalStorageService.getItem('onBoard')) {
        setIsOnBoardingModalOpen(true);
        LocalStorageService.setItem('onBoard', true);
      } else {
        setIsOnBoardingModalOpen(false);
      }
      if (progress === 100 && isOnBoarding) {
        setIsOnBoarding(false);
        setIsCompletedModalOpen(true);
      }
      setProfile(profileData);
    }
  }, [user, company, !!openedModal]);

  useEffect(() => {
    if (isSuccess) {
      setIsOnBoarding(false);
      setOpenedModal(null);
      updateAbn(abnNumberValue || '');
      updateFreeDeliveryAt(freeDeliveryAtValue || 0);
    }
  }, [isSuccess]);

  useEffect(() => {
    updateProfileProgress(calculateProfileProgress(profile));
  }, [profile]);

  const onTopUpOrdersChange = (isChecked: boolean) => {
    setTopUpOrders(isChecked);
    if (!company?.id) {
      return;
    }
    const formData = new FormData();
    profile?.delivery_detail_id && formData.append('company[delivery_details_attributes][0][id]', profile.delivery_detail_id.toString());
    formData.append('company[delivery_details_attributes][0][top_up_orders]', (isChecked).toString());  
    updateProfile({
      formData,
      id: company?.id,
    });
  };

  const addressLastLine = `${profile.address?.city || ''} ${
    ((profile.address?.state || '')[0] || '').toUpperCase() + (profile.address?.state || '').slice(1)?.toLowerCase()
  }, ${profile.address?.postcode || ''}`.trim();

  return (
    <>
      <TopNavBar
        isAccount={true}
        leftComponent={<NavbarTitle title='Company Profile & Settings' showBackBtn={true} backHandler={openMenus} />}
        rightComponent={isTablet && profileProgress < 70 && profileProgress > 10 && <ProfileProgressBar progress={profileProgress} />}
        bottomComponent={
          isMobile ? <NavbarTitle title='Company Profile & Settings' showBackBtn={true} backHandler={openMenus} /> : undefined
        }
      />
      <MainNavBar isAccount={true} />
      <div
        className={clsx({
          [classes.accountContainer]: true,
          [classes.accountContainerProfileCompleted]: profileProgress >= 70,
        })}
      >
        <div className={classes.accountHeader}>
          <div className={classes.accountTitle}>My Account</div>
          {profileProgress < 70 && profileProgress > 10 && <ProfileProgressBar progress={profileProgress} />}
        </div>
        <div className={classes.accountBody}>
          <AccountMenu showBack={true} />
          <div className={classes.accountContent}>
            <div className={classes.root}>
              {profileProgress < 70 && (
                <div className={classes.mobileProgress}>
                  <ProfileProgressBar progress={profileProgress} />
                </div>
              )}
              <div className={classes.topBox}>
                <div className={classes.header}>
                  <div className={classes.textWrap}>
                    <div className={classes.titleBox}>
                      <span className={classes.titleText}>{profile.name}</span>
                      <EditIcon onClick={setNextStep.bind(null, ProfileModals.BUSINESS_DETAILS)} />
                    </div>
                    {profile.bio ? (
                      <div className={clsx([classes.contentText, classes.description])}>
                        {profile.bio}
                        <EditIcon onClick={onUpdateDescriptionClick} />
                      </div>
                    ) : (
                      <div className={classes.addDescription}>
                        <AddButton isDark={true} onClick={onUpdateDescriptionClick} />
                        <span className={classes.addText}>Add a description</span>
                      </div>
                    )}
                  </div>
                  <div className={clsx([classes.imgWrap, rippleClass.ripple])} onClick={onUploadLogoClick}>
                    {profile.picture_url ? (
                      <>
                        <div className={classes.addPhotoBg}>
                          <EditIcon disabled={true} />
                        </div>
                        <BlurredImage src={profile.picture_url} />
                      </>
                    ) : (
                      <div className={classes.uploadPhoto}>
                        <SvgUploadPhoto />
                        Upload logo
                      </div>
                    )}
                  </div>
                </div>
                <div className={classes.mainInfoBox}>
                  <div className={classes.businessInfoItem}>
                    <div className={classes.subTitleWrap}>
                      <div className={classes.subTitleText}>WEBSITE</div>
                      {profile.website && (
                        <>
                          <div
                            className={clsx([classes.subTitleText, classes.editText])}
                            onClick={setNextStep.bind(null, ProfileModals.BUSINESS_DETAILS)}
                          >
                            Edit
                          </div>
                          <EditIcon onClick={setNextStep.bind(null, ProfileModals.BUSINESS_DETAILS)} />
                        </>
                      )}
                    </div>
                    {profile.website ? (
                      <div className={classes.websiteText}>
                        <a
                          href={`https://${profile.website.replaceAll('https://', '').replaceAll('http://', '')}`}
                          target='_blank'
                          rel='noreferrer'
                        >
                          {profile.website}
                        </a>
                      </div>
                    ) : (
                      <>
                        <AddButton isDark={true} onClick={setNextStep.bind(null, ProfileModals.BUSINESS_DETAILS)} />
                        <span className={classes.addText}>Add your website</span>
                      </>
                    )}
                  </div>
                  <div className={classes.businessInfoItem}>
                    <div className={classes.subTitleWrap}>
                      <div className={classes.subTitleText}>ADDRESS</div>
                      {(profile.address?.street_address1 ||
                        profile.address?.street_address2 ||
                        addressLastLine.replace(',', '').trim()) && (
                        <>
                          <div
                            className={clsx([classes.subTitleText, classes.editText])}
                            onClick={setNextStep.bind(null, ProfileModals.ADDRESS)}
                          >
                            Edit
                          </div>
                          <EditIcon onClick={setNextStep.bind(null, ProfileModals.ADDRESS)} />
                        </>
                      )}
                    </div>
                    {profile.address?.street_address1 || profile.address?.street_address2 || addressLastLine.replace(',', '').trim() ? (
                      <>
                        <div className={classes.contentText}>{profile.address?.street_address1 || ''}</div>
                        <div className={classes.contentText}>{profile.address?.street_address2 || ''}</div>
                        <div className={classes.contentText}>{addressLastLine}</div>
                      </>
                    ) : (
                      <>
                        <AddButton isDark={true} onClick={setNextStep.bind(null, ProfileModals.ADDRESS)} />
                        <span className={classes.addText}>Add your address</span>
                      </>
                    )}
                  </div>
                  <div className={classes.businessInfoItem}>
                    <div className={classes.subTitleWrap}>
                      <div className={classes.subTitleText}>{abnText}</div>
                      {user?.abn_number && (
                        <>
                          <div
                            className={clsx([classes.subTitleText, classes.editText])}
                            onClick={setNextStep.bind(null, ProfileModals.BUSINESS_DETAILS)}
                          >
                            Edit
                          </div>
                          <EditIcon onClick={setNextStep.bind(null, ProfileModals.BUSINESS_DETAILS)} />
                        </>
                      )}
                    </div>
                    {user?.abn_number ? (
                      <div>{user?.abn_number}</div>
                    ) : (
                      <>
                        <AddButton isDark={true} onClick={setNextStep.bind(null, ProfileModals.BUSINESS_DETAILS)} />
                        <span className={classes.addText}>Add {abnText}</span>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className={classes.contactsBlock}>
                <div className={classes.mainBoxRow} onClick={toggleExpandedSection.bind(null, ExpandedSection.contact)}>
                  <div className={classes.mainBoxRowTitle}>Company Contact details</div>
                  {!expandedSections.includes(ExpandedSection.contact) && (
                    <div className={classes.mainBoxRowText}>Emails & Phone numbers</div>
                  )}
                  <div className={classes.rowAction}>
                    <span
                      className={clsx(
                        classes.actionsText,
                        expandedSections.includes(ExpandedSection.contact) && classes.actionsTextExpanded,
                      )}
                    >
                      {expandedSections.includes(ExpandedSection.contact) ? 'Hide' : 'Show'}
                    </span>
                    <ChevronRightIcon
                      className={clsx(
                        classes.actionsIcon,
                        expandedSections.includes(ExpandedSection.contact) && classes.actionsIconRotated,
                      )}
                    />
                  </div>
                </div>
                <Collapse in={expandedSections.includes(ExpandedSection.contact)} timeout={500}>
                  <div className={classes.contactsBlockContent}>
                    <div className={classes.contactBlockItem}>
                      <div className={classes.subTitleWrap}>
                        <div className={classes.subTitleText}>{isMobile ? 'Email' : 'Company Email'}</div>
                        {profile?.contacts?.find((contact) => contact.contact_type === ContactType.OFFICE)?.email && (
                          <>
                            <div className={clsx([classes.subTitleText, classes.editText])} onClick={onUpdateCompanyClick}>
                              Edit
                            </div>
                            <EditIcon onClick={onUpdateCompanyClick} />
                          </>
                        )}
                      </div>
                      {profile?.contacts?.find((contact) => contact.contact_type === ContactType.OFFICE)?.email ? (
                        <div className={classes.contentText}>
                          <span className={classes.mobileInfoTitle}>company</span>
                          {profile?.contacts?.find((contact) => contact.contact_type === ContactType.OFFICE)?.email}
                        </div>
                      ) : (
                        <>
                          <AddButton isDark={true} onClick={onUpdateCompanyClick} />
                          <span className={classes.addText}>Add your company email</span>
                        </>
                      )}
                    </div>
                    <div className={classes.contactBlockItem}>
                      <div className={classes.subTitleWrap}>
                        {!isMobile && <div className={classes.subTitleText}>Order Email</div>}
                        {profile?.contacts?.find((contact) => contact.contact_type === ContactType.ORDER)?.email && !isMobile && (
                          <>
                            <div className={clsx([classes.subTitleText, classes.editText])} onClick={onUpdateCompanyClick}>
                              Edit
                            </div>
                            <EditIcon onClick={onUpdateCompanyClick} />
                          </>
                        )}
                      </div>
                      {profile?.contacts?.find((contact) => contact.contact_type === ContactType.ORDER)?.email ? (
                        <>
                          <div className={classes.contentText}>
                            <span className={classes.mobileInfoTitle}>order</span>
                            {profile?.contacts?.find((contact) => contact.contact_type === ContactType.ORDER)?.email}
                          </div>
                          <CommonCheckbox
                            checked={!!profile?.contacts?.find((contact) => contact.contact_type === ContactType.ORDER)?.is_order_email}
                            name='Email for Orders'
                            onChange={toggleEmailAllOrders}
                            labelPlace='end'
                            disabled={isLoading}
                          />
                        </>
                      ) : (
                        <>
                          <AddButton isDark={true} onClick={onUpdateCompanyClick} />
                          <span className={classes.addText}>Add your order email</span>
                        </>
                      )}
                    </div>
                    <div className={clsx(classes.contactBlockItem, classes.phoneBlock)}>
                      <div className={classes.subTitleWrap}>
                        <div className={classes.subTitleText}>Phone</div>
                        {(profile?.contacts?.find((contact) => contact.contact_type === ContactType.ORDER)?.phone_number ||
                          profile?.contacts?.find((contact) => contact.contact_type === ContactType.OFFICE)?.phone_number) && (
                          <>
                            <div className={clsx([classes.subTitleText, classes.editText])} onClick={onUpdateCompanyClick}>
                              Edit
                            </div>
                            <EditIcon onClick={onUpdateCompanyClick} />
                          </>
                        )}
                      </div>
                      {profile?.contacts?.find((contact) => contact.contact_type === ContactType.ORDER)?.phone_number ||
                      profile?.contacts?.find((contact) => contact.contact_type === ContactType.OFFICE)?.phone_number ? (
                        <>
                          <div className={classes.contentText}>
                            <span className={classes.mobileInfoTitle}>{isWhatsAppOrdering ? 'CONTACT' : 'OFFICE'}</span>
                            {profile?.contacts?.find((contact) => contact.contact_type === ContactType.OFFICE)?.phone_number || ''}
                            {profile?.contacts?.find((contact) => contact.contact_type === ContactType.OFFICE)?.phone_number && (
                              <span className={classes.contactLabel}>{isWhatsAppOrdering ? 'Contact phone' : 'Office phone'}</span>
                            )}
                          </div>
                          <div className={classes.contentText}>
                            <span className={classes.mobileInfoTitle}>{isWhatsAppOrdering ? 'ORDERS' : 'CONTACT'}</span>
                            {profile?.contacts?.find((contact) => contact.contact_type === ContactType.ORDER)?.phone_number || ''}
                            {profile?.contacts?.find((contact) => contact.contact_type === ContactType.ORDER)?.phone_number && (
                              <span className={classes.contactLabel}>{isWhatsAppOrdering ? 'Orders phone' : 'Contact phone'}</span>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <AddButton isDark={true} onClick={onUpdateCompanyClick} />
                          <span className={classes.addText}>Add phone numbers</span>
                        </>
                      )}
                    </div>
                  </div>
                </Collapse>
              </div>

              <div className={classes.personalBlock}>
                <div className={classes.mainBoxRow} onClick={toggleExpandedSection.bind(null, ExpandedSection.delivery)}>
                  <div className={classes.mainBoxRowTitle}>DELIVERY Settings</div>
                  {!expandedSections.includes(ExpandedSection.delivery) && (
                    <div className={classes.mainBoxRowText}>Delivery Days, Radius & Fee</div>
                  )}
                  <div className={classes.rowAction}>
                    <span
                      className={clsx(
                        classes.actionsText,
                        expandedSections.includes(ExpandedSection.delivery) && classes.actionsTextExpanded,
                      )}
                    >
                      {expandedSections.includes(ExpandedSection.delivery) ? 'Hide' : 'Show'}
                    </span>
                    <ChevronRightIcon
                      className={clsx(
                        classes.actionsIcon,
                        expandedSections.includes(ExpandedSection.delivery) && classes.actionsIconRotated,
                      )}
                    />
                  </div>
                </div>
                <Collapse in={expandedSections.includes(ExpandedSection.delivery)} timeout={500}>
                  <div className={classes.deliveryBox}>
                    <div className={classes.leftWrap}>
                      <div>
                        <div className={classes.subTitleWrap}>
                          <div className={classes.subTitleText}>DELIVERY DAYS</div>
                          {profile.delivery_days && (
                            <>
                              <div
                                className={clsx([classes.subTitleText, classes.editText])}
                                onClick={setNextStep.bind(null, ProfileModals.DELIVERY_DAYS)}
                              >
                                Edit
                              </div>
                              <EditIcon onClick={setNextStep.bind(null, ProfileModals.DELIVERY_DAYS)} />
                            </>
                          )}
                        </div>
                        {profile.delivery_days ? (
                          <div className={classes.weekDaysBox}>
                            {week.map(({ title, key }) => (
                              <div
                                key={key}
                                className={clsx({
                                  [classes.weekDayItem]: true,
                                  [classes.weekDayItemSelected]: profile.delivery_days?.includes(key),
                                })}
                              >
                                <CheckIcon className={classes.checkIcon} />
                                {title}
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className={classes.addDeliveryWrap}>
                            <AddButton isDark={true} onClick={setNextStep.bind(null, ProfileModals.DELIVERY_DAYS)} />
                            <span className={classes.addText}>Add Delivery Days</span>
                          </div>
                        )}
                      </div>
                      <div className={classes.orderInfoWrap}>
                        <div className={classes.mainInfoItem}>
                          <div>
                            <div className={classes.subTitleWrap}>
                              <div className={classes.subTitleText}>MINIMUM ORDER VALUE</div>
                              {(!!profile.min_order_value || profile.min_order_value === 0) && (
                                <>
                                  <div className={clsx([classes.subTitleText, classes.editText])} onClick={onUpdateMinimumOrderClick}>
                                    Edit
                                  </div>
                                  <EditIcon onClick={onUpdateMinimumOrderClick} />
                                </>
                              )}
                            </div>
                            {profile.min_order_value ? (
                              <div className={classes.contentText}>{formatCurrency(profile?.min_order_value || 0)}</div>
                            ) : (
                              <>
                                <AddButton isDark={true} onClick={onUpdateMinimumOrderClick} />
                                <span className={classes.addText}>Add your minimum order value</span>
                              </>
                            )}
                          </div>
                          <div className={classes.infoItemWrap}>
                            <div className={classes.subTitleWrap}>
                              <div className={classes.subTitleText}>ORDER CUT-OFF TIME</div>
                              {profile.cutoff && (
                                <>
                                  <div
                                    className={clsx([classes.subTitleText, classes.editText])}
                                    onClick={setNextStep.bind(null, ProfileModals.ORDER_DETAILS)}
                                  >
                                    Edit
                                  </div>
                                  <EditIcon onClick={setNextStep.bind(null, ProfileModals.ORDER_DETAILS)} />
                                </>
                              )}
                            </div>
                            {profile.cutoff ? (
                              <div className={classes.contentText}>{format(new Date(profile.cutoff), 'H:mm aaa')}</div>
                            ) : (
                              <>
                                <AddButton isDark={true} onClick={setNextStep.bind(null, ProfileModals.ORDER_DETAILS)} />
                                <span className={classes.addText}>Add Cut-off Time</span>
                              </>
                            )}
                          </div>
                        </div>
                        <div className={classes.mainInfoItem}>
                          <div className={classes.subTitleWrap}>
                            <div className={classes.subTitleText}>TOP-UP ORDER</div>
                            <Switch
                              checked={topUpOrders}
                              color='primary'
                              classes={{
                                thumb: classes.thumb,
                                track: classes.track,
                                checked: classes.checked,
                              }}
                              onChange={(e) => onTopUpOrdersChange(e.target.checked) }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={classes.rightWrap}>
                      <div className={clsx(classes.subTitleWrap, classes.radiusTitle)}>
                        <div className={classes.subTitleText}>DELIVERY RADIUS (km)</div>
                        {(!!profile.delivery_radius || profile.delivery_radius === 0) && (
                          <>
                            <div className={clsx([classes.subTitleText, classes.editText])} onClick={onUpdateDeliveryRadiusClick}>
                              Edit
                            </div>
                            <EditIcon onClick={onUpdateDeliveryRadiusClick} />
                          </>
                        )}
                      </div>
                      <div className={classes.radiusBox} onClick={onUpdateDeliveryRadiusClick}>
                        {profile.delivery_radius || profile.delivery_radius === 0 ? profile.delivery_radius : 'Enter delivery radius'} (km)
                      </div>
                      <div className={classes.deliveryFeeBox}>
                        <div className={classes.subTitleWrap}>
                          <div className={classes.subTitleText}>DELIVERY FEE</div>
                          {(!!profile?.delivery_fee || profile?.delivery_fee === 0) && (
                            <>
                              <div className={clsx([classes.subTitleText, classes.editText])} onClick={onUpdateDeliveryFeeClick}>
                                Edit
                              </div>
                              <EditIcon onClick={onUpdateDeliveryFeeClick} />
                            </>
                          )}
                        </div>
                        {!!profile?.delivery_fee || profile.delivery_fee === 0 ? (
                          <div className={classes.contentText}>{formatCurrency(profile.delivery_fee)}</div>
                        ) : (
                          <>
                            <AddButton isDark={true} onClick={onUpdateDeliveryFeeClick} />
                            <span className={classes.addText}>Add Delivery Fee</span>
                          </>
                        )}
                      </div>
                      <div className={classes.deliveryFeeAtBox}>
                        <div className={classes.subTitleWrap}>
                          <div className={classes.subTitleText}>FREE DELIVERY AT</div>
                          {(!!freeDeliveryAt?.fee_delivery_at || freeDeliveryAt?.fee_delivery_at === 0) && (
                            <>
                              <div
                                className={clsx([classes.subTitleText, classes.editText])}
                                onClick={setNextStep.bind(null, ProfileModals.DELIVERY_DETAILS)}
                              >
                                Edit
                              </div>
                              <EditIcon onClick={setNextStep.bind(null, ProfileModals.DELIVERY_DETAILS)} />
                            </>
                          )}
                        </div>
                        {freeDeliveryAt?.fee_delivery_at || freeDeliveryAt?.fee_delivery_at === 0 ? (
                          <div className={classes.contentText}>{formatCurrency(freeDeliveryAt.fee_delivery_at)}</div>
                        ) : (
                          <>
                            <AddButton isDark={true} onClick={setNextStep.bind(null, ProfileModals.DELIVERY_DETAILS)} />
                            <span className={classes.addText}>Add Free Delivery At</span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </Collapse>
              </div>

              <div className={classes.personalBlock}>
                <div className={classes.mainBoxRow} onClick={toggleExpandedSection.bind(null, ExpandedSection.advanced)}>
                  <div className={classes.mainBoxRowTitle}>ADVANCED DELIVERY Settings</div>
                  {!expandedSections.includes(ExpandedSection.advanced) ? (
                    <div className={classes.mainBoxRowText}>Post codes & Delivery costs</div>
                  ) : (
                    <div className={classes.mainBoxRowText}>These settings override the standard delivery settings</div>
                  )}
                  <div className={classes.rowAction}>
                    {subscriptionType === SubscriptionPlanEnum.FREE && <SvgLockIcon className={classes.lockIcon} />}
                    <span
                      className={clsx(
                        classes.actionsText,
                        expandedSections.includes(ExpandedSection.advanced) && classes.actionsTextExpanded,
                      )}
                    >
                      {expandedSections.includes(ExpandedSection.advanced) ? 'Hide' : 'Show'}
                    </span>
                    <ChevronRightIcon
                      className={clsx(
                        classes.actionsIcon,
                        expandedSections.includes(ExpandedSection.advanced) && classes.actionsIconRotated,
                      )}
                    />
                  </div>
                </div>
                <Collapse in={expandedSections.includes(ExpandedSection.advanced)} timeout={500}>
                  <>
                    {subscriptionType === SubscriptionPlanEnum.FREE && (
                      <div className={classes.upgradeBlock}>
                        <div className={classes.upgradeBoxContent}>
                          <div className={classes.lockBox}>
                            <SvgLockIcon color={colorVariables.white} width={20} height={20} />
                          </div>
                          <p>Upgrade your subscription plan to unlock ‘Advanced delivery settings’.</p>
                        </div>
                        <ThemedButton
                          onClick={onUpgrade}
                          title='Upgrade'
                          isSmall={true}
                          width={isMobile ? 160 : 95}
                          buttonStyle='blueButton'
                        />
                      </div>
                    )}
                    <div className={clsx(subscriptionType === SubscriptionPlanEnum.FREE && classes.advancedBoxWrap)}>
                      <AdvancedDeliveryContent days={profile?.delivery_days || []} />
                      {subscriptionType === SubscriptionPlanEnum.FREE && <div className={classes.advancedBoxOverlay} />}
                    </div>
                  </>
                </Collapse>
              </div>

              <div className={classes.personalBlock}>
                <div className={classes.mainBoxRow} onClick={toggleExpandedSection.bind(null, ExpandedSection.products)}>
                  <div className={classes.mainBoxRowTitle}>TYPES OF PRODUCTS SOLD</div>
                  {!expandedSections.includes(ExpandedSection.products) && (
                    <div className={classes.categoriesText}>
                      <LinesEllipsis text={profile?.categories?.join(', ') || ''} ellipsis='...' maxLine={1} />
                    </div>
                  )}
                  <div className={classes.rowAction}>
                    <span
                      className={clsx(
                        classes.actionsText,
                        expandedSections.includes(ExpandedSection.products) && classes.actionsTextExpanded,
                      )}
                    >
                      {expandedSections.includes(ExpandedSection.products) ? 'Hide' : 'Show'}
                    </span>
                    <ChevronRightIcon
                      className={clsx(
                        classes.actionsIcon,
                        expandedSections.includes(ExpandedSection.products) && classes.actionsIconRotated,
                      )}
                    />
                  </div>
                </div>
                <Collapse in={expandedSections.includes(ExpandedSection.products)} timeout={500}>
                  <div className={classes.productsBox}>
                    <div className={classes.subTitleWrap}>
                      {!!profile.categories?.length && (
                        <>
                          <div className={clsx([classes.subTitleText, classes.editText])} onClick={onUpdateProductTypeClick}>
                            Edit
                          </div>
                          <EditIcon onClick={onUpdateProductTypeClick} />
                        </>
                      )}
                    </div>
                    {!!profile.categories?.length ? (
                      <div className={classes.productsList}>
                        {productsCategoriesList.map(({ title, icon }) => (
                          <div
                            key={title}
                            className={clsx({
                              [classes.productItem]: true,
                              [classes.productItemSelected]: profile.categories?.includes(title),
                            })}
                          >
                            <div className={classes.productIcon}>{icon}</div>
                            {title}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <>
                        <AddButton isDark={true} onClick={setNextStep.bind(null, ProfileModals.PRODUCT_TYPES)} />
                        <span className={classes.addText}>Add Categories</span>
                      </>
                    )}
                  </div>
                </Collapse>
              </div>
            </div>
            <BusinessDetailsForm
              isModalOpen={openedModal === ProfileModals.BUSINESS_DETAILS}
              values={{ name: profile.name as string, website: profile.website, bio: profile.bio, abn: user?.abn_number }}
              onClose={closeModal}
              onSubmit={onSubmitForm}
              setNextStep={setNextStep}
              isOnBoarding={isOnBoarding}
              companyId={company?.id}
              setAbnValue={setAbnValue}
            />
            <AddressForm
              values={profile.address}
              onClose={closeModal}
              onSubmit={onSubmitForm}
              isModalOpen={openedModal === ProfileModals.ADDRESS}
              setNextStep={setNextStep}
              isOnBoarding={isOnBoarding}
              companyId={company?.id}
            />
            <CompanyDetailsForm
              values={profile?.contacts || []}
              onClose={closeModal}
              onSubmit={onSubmitForm}
              isModalOpen={openedModal === ProfileModals.COMPANY_DETAILS}
              isOnBoarding={isOnBoarding}
              setNextStep={setNextStep}
              companyId={company?.id}
            />
            <DeliveryDetailsForm
              values={{
                delivery_radius: profile?.delivery_radius,
                free_delivery_at: freeDeliveryAt?.fee_delivery_at || '0',
                delivery_detail_id: profile?.delivery_detail_id || undefined,
                supplier_id: user?.id,
                delivery_radius_id: profile?.delivery_radius_id,
                delivery_fee: profile?.delivery_fee || '0',
              }}
              onClose={closeModal}
              onSubmit={onSubmitForm}
              isModalOpen={openedModal === ProfileModals.DELIVERY_DETAILS}
              isOnBoarding={isOnBoarding}
              setNextStep={setNextStep}
              companyId={company?.id}
              setFreeDelivery={setFreeDelivery}
            />
            <DeliveryDaysBlock
              values={{
                days: profile?.delivery_days || [],
                delivery_detail_id: profile?.delivery_detail_id,
                supplier_id: user?.id,
              }}
              onClose={closeModal}
              onSubmit={onSubmitForm}
              isModalOpen={openedModal === ProfileModals.DELIVERY_DAYS}
              isOnBoarding={isOnBoarding}
              setNextStep={setNextStep}
              companyId={company?.id}
            />
            <ProductCategories
              companyCategories={company?.company_categories || []}
              values={profile.categories || []}
              onClose={closeModal}
              onSubmit={onSubmitForm}
              isModalOpen={openedModal === ProfileModals.PRODUCT_TYPES}
              isOnBoarding={isOnBoarding}
              setNextStep={setNextStep}
              companyId={company?.id}
              saveUpdates={onSaveProfile}
              disableSubmit={isLoading}
            />
            <OrderDetails
              values={{
                cutoff: profile.cutoff,
                min_order_value: profile?.min_order_value ? profile.min_order_value.toString() : '0',
                supplier_id: user?.id,
                delivery_detail_id: profile?.delivery_detail_id || undefined,
              }}
              onClose={closeModal}
              onSubmit={onSubmitForm}
              isModalOpen={openedModal === ProfileModals.ORDER_DETAILS}
              isOnBoarding={isOnBoarding}
              setNextStep={setNextStep}
              companyId={company?.id}
            />
            <UploadLogo
              picture_url={profile.picture_url}
              isModalOpen={openedModal === ProfileModals.UPLOAD_LOGO}
              onClose={closeModal}
              onSubmit={onSubmitForm}
              isOnBoarding={isOnBoarding}
              setNextStep={setNextStep}
              companyId={company?.id}
            />
            <InitialSetUp onClose={closeOnBoardingModal} isModalOpen={isOnBoardingModalOpen} onSetOnBoarding={onSetOnBoarding} />
            <SetUpCompleted onClose={closeSetUpCompleted} isModalOpen={isCompletedModalOpen} />
          </div>
        </div>
      </div>
    </>
  );
};

import React, { KeyboardEvent, useEffect, useState } from 'react';

import clsx from 'clsx';

import { useComponentVisible } from './use-visible-hook';

import { useStyles } from './style';

interface Props {
  items: { title?: string; handler?: (...args: unknown[]) => void }[] | React.ReactElement[];
  top?: number;
  right?: number;
  align?: 'left' | 'right' | 'center';
  isOpen?: boolean;
  onClose?: (...args: unknown[]) => void;
  onOpen?: (...args: unknown[]) => void;
  triggerBtn?: React.ReactNode;
  closeOnClick?: boolean;
  dropdownClass?: string;
}

export const Dropdown: React.FC<Props> = React.memo(
  ({ items = [], top = 0, right = 0, align = 'center', triggerBtn, closeOnClick = false, onOpen, onClose, isOpen, dropdownClass }) => {
    const classes = useStyles();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleClick = (handler?: () => void) => {
      handler && handler();
      closeOnClick && handleClose();
    };

    const handleOpen = () => {
      setIsDropdownOpen(!isDropdownOpen);
    };

    const handleClose = () => {
      setIsDropdownOpen(false);
    };

    useEffect(() => {
      isDropdownOpen && onOpen && onOpen();
      !isDropdownOpen && onClose && onClose();
    }, [isDropdownOpen]);

    const { ref, isComponentVisible, triggerRef } = useComponentVisible(isDropdownOpen, handleClose);

    useEffect(() => {
      isOpen !== undefined && setIsDropdownOpen(isOpen);
    }, [isOpen]);

    const onKeyUp = (e: KeyboardEvent<HTMLElement>) => {
      if (e.key === 'ArrowDown') {
        (e.currentTarget?.nextSibling as HTMLElement)?.focus();
      }
      if (e.key === 'ArrowUp') {
        (e.currentTarget?.previousElementSibling as HTMLElement)?.focus();
      }
      if (e.key === 'Enter') {
        e.currentTarget.click();
      }
    };

    return (
      <>
        <div ref={triggerRef} onClick={handleOpen}>
          {triggerBtn}
        </div>
        {isComponentVisible ? (
          <div className={classes.container} ref={ref}>
            <div className={clsx(classes.dropdown, dropdownClass)} style={{ right, top }}>
              {items.map((item, idx) => {
                if (React.isValidElement(item)) {
                  return (
                    <div
                      className={clsx([classes.item, classes.checkbox])}
                      style={{ textAlign: align }}
                      key={item.key}
                      onClick={closeOnClick ? handleClose : undefined}
                    >
                      {item}
                    </div>
                  );
                } else {
                  const { title, handler } = item;
                  return title ? (
                    <div
                      onKeyUp={onKeyUp}
                      tabIndex={idx + 10}
                      key={title}
                      onClick={handleClick.bind(null, handler)}
                      className={classes.item}
                    >
                      {title}
                    </div>
                  ) : null;
                }
              })}
            </div>
          </div>
        ) : null}
      </>
    );
  },
);

export const roundNumbers = (n: string | number | null | undefined, down?: boolean) => {
  switch (typeof n) {
    case 'number':
      return down ? Math.floor(n * 100) / 100 : Math.round(n * 100) / 100;
    case 'string':
      return down ? Math.floor(parseFloat(n) * 100) / 100 : Math.round(parseFloat(n) * 100) / 100;
    default:
      return 0;
  }
};

export const roundUpToNearestUnit = (num: number, unit: number) => {
  return Math.ceil(num / unit) * unit;
}
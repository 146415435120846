import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import { getInventorySortFilters, getInventoryStorageFilters, setInventoryPage } from '../../store/inventory';
import { getMySubscriptionType } from '../../store/user';
import { SubscriptionPlanEnum } from '../user/types';
import {
  useGetAllInventoriesQuery,
  useGetInventoriesQuery,
  useGetInventoryStorageProductsQuery,
  useGetInventoryStoragesQuery,
} from './index';
import { Inventory } from './types';

export const useGetInventoriesList = (inView: boolean) => {
  const dispatch = useAppDispatch();
  const { keyword, filter, supplier: supplierId, currentPage, sortBy: sort } = useAppSelector(getInventorySortFilters);

  const { data, isFetching, isSuccess, isLoading } = useGetInventoriesQuery({
    supplierId,
    keyword,
    favourite: filter?.favourite || undefined,
    recently: filter?.recently || undefined,
    page: currentPage,
    status: filter?.status,
    sort,
  });

  useEffect(() => {
    inView && data?.next_page && dispatch(setInventoryPage(data.next_page));
  }, [inView, data?.next_page]);

  return { isFetching, isSuccess, isLoading };
};

export const useGetInventoryStorages = () => {
  const { data, isFetching } = useGetInventoryStoragesQuery();
  return { storages: data?.storages, loading: isFetching };
};

export const useGetInventoryStorageProducts = () => {
  const { storage } = useAppSelector(getInventorySortFilters);
  const { recently } = useAppSelector(getInventoryStorageFilters);
  const { data, isFetching, isLoading } = useGetInventoryStorageProductsQuery({ name: storage || undefined, recently_purchased: recently });
  return { storageData: data?.storages[0], loading: isFetching, initLoading: isLoading };
};

export const useGetAllInventories = () => {
  const plan = useAppSelector(getMySubscriptionType);
  const { data } = useGetInventoryStoragesQuery(undefined, { skip: plan !== SubscriptionPlanEnum.HOSPO });
  return useMemo(() => {
    return data?.storages.reduce((acc: Inventory[], el) => {
      acc.push(...(el.inventory_products || []));
      return acc;
    }, []);
  }, [data]);
};

export const useCheckIsProductInInventory = (id: number) => {
  const { data } = useGetAllInventoriesQuery();

  return useMemo(() => {
    return !!data?.inventory_products.some((el) => el.product_id === id);
  }, [data, id]);
};
